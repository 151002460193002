import React from 'react'

import Layout from '../components/Layout'
import AboutHero from '../components/AboutHero'
import AboutUs from '../components/AboutUs'
import Rewards from '../components/Rewards'
import Clients from '../components/Clients'
import AboutPath from '../components/AboutPath'
import Whitepaper from '../components/Whitepaper'
import ReviewsSlider from '../components/ReviewsSlider/ReviewsSlider'

const About = () => (
	<Layout
		title="О студии разработки мобильных приложений AppCraft"
		content="✅ Информация о студии разработки мобильных приложений. Команда специалистов, гарантия на код, многолетний опыт разработки на заказ."
		keywords="разработка мобильных приложений, заказать мобильное приложение, мобильное приложение на заказ, стоимость мобильного приложения, стартап, продвижение"
		ogSiteName="AppCraft"
		ogTitle="Студия Appcraft | Разработка мобильных приложений для Android и iOS"
		ogDescription="Разработка мобильных продуктов от студии, которая создает и развивает собственные проекты. Эффективные решения для клиентов на iOS и Android, основанные на собственном опыте"
	>
		<AboutHero />
		<AboutUs />
		<Rewards />
		<Clients />
		<ReviewsSlider isAbout={true} />
		<AboutPath />
		<section className="container mb-24">
			<Whitepaper />
		</section>
	</Layout>
)

export default About
