import React from 'react'

import Timeline from '../Timeline'

const AboutPath = () => (
  <section className="aboutpath container" id="ourpath">
    <h2 className="aboutpath-title font-bold leading-tight sm:text-28">
      Путь нашего развития
    </h2>

    <Timeline />
  </section>
)

export default AboutPath
