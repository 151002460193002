import React from 'react'
import ScrollContainer from 'react-indiana-drag-scroll'

import Icon from '../Icon'

const Revards = () => (
  <section className="rewards container">
    <h2 className="rewards-title text-22 sm:text-42 font-bold leading-none">
      Наши награды
    </h2>
    <ScrollContainer className="rewards-list">
      <article className="rewards-unit">
        <Icon name="cup" width="38" height="44" className="rewards-icon" />
        <h3 className="font-bold leading-tight text-xs sm:text-base">
          1 место
          <br />
          Музыкальные и видео приложения
        </h3>
        <p className="text-xs sm:text-sm">Рейтинг Рунета 2017</p>
      </article>

      <article className="rewards-unit">
        <Icon name="cup" width="38" height="44" className="rewards-icon" />
        <h3 className="font-bold leading-tight text-xs sm:text-base">
          1 место
          <br />
          Развлекательные приложения
        </h3>
        <p className="text-xs sm:text-sm">Рейтинг Рунета 2017</p>
      </article>

      <article className="rewards-unit">
        <Icon name="cup" width="38" height="44" className="rewards-icon" />
        <h3 className="font-bold leading-tight text-xs sm:text-base">
          3 место
          <br />
          Разработчики мобильных приложений
        </h3>
        <p className="text-xs sm:text-sm">Рейтинг Рунета 2018</p>
      </article>

      <article className="rewards-unit">
        <Icon name="cup" width="38" height="44" className="rewards-icon" />
        <h3 className="font-bold leading-tight text-xs sm:text-base">
          1 место
          <br />
          Развлекательные приложения
        </h3>
        <p className="text-xs sm:text-sm">Рейтинг Рунета 2019</p>
      </article>

      <article className="rewards-unit">
        <Icon name="cup" width="38" height="44" className="rewards-icon" />
        <h3 className="font-bold leading-tight text-xs sm:text-base">
          1 место
          <br />
          Разработчики мобильных приложений
        </h3>
        <p className="text-xs sm:text-sm">Рейтинг Рунета 2019</p>
      </article>

      <article className="rewards-unit">
        <Icon name="cup" width="38" height="44" className="rewards-icon" />
        <h3 className="font-bold leading-tight text-xs sm:text-base">
          2 место
          <br />
          Музыкальные и видео приложения
        </h3>
        <p className="text-xs sm:text-sm">Рейтинг Рунета 2019</p>
      </article>

    </ScrollContainer>
  </section>
)

export default Revards
