import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import Ticker from 'react-ticker'

import { useDelayedRender } from '../../hooks/useDelayedRender'

import './Clients.css'

const DelayedRender = ({ delay, children }) => useDelayedRender(delay)(() => children)

const Clients = () => {
  const {
    allFile: { edges: logos },
  } = useStaticQuery(graphql`
    query {
      allFile(filter: { relativePath: { regex: "/client/" } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 170, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
            publicURL
          }
        }
      }
    }
  `)

  const renderLogos = logos.map(({ node: logo }, key) => <img src={logo.publicURL} alt="" key={key} />)

  return (
    <section className="clients  overflow-hidden">
      <h2 className="clients-title container text-22 sm:text-42 font-bold leading-none">Наши клиенты</h2>

      <Ticker speed={5} height={100}>
        {() => <ul className="homeclients-list">{renderLogos}</ul>}
      </Ticker>
    </section>
  )
}

export default Clients
