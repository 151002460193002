import React from 'react'

import Stripe from '../Stripe'

const AboutHero = () => {
  return (
    <section className="abouthero">
      <h1 className="abouthero-title">
        Мы все разные, <br /> но одинаково <em>любим свою работу!</em>
      </h1>
      <Stripe />
    </section>
  )
}

export default AboutHero
