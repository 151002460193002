import React, { useEffect, useRef } from 'react'
import throttle from 'lodash/throttle'

const SCROLL_SPEED = 0.3
const NOISE_SPEED = 0.004
const NOISE_AMOUNT = 5
const CANVAS_WIDTH = 2300

// prettier-ignore
const bubblesSpecs = [
  { s: 1.1, x: 30, y: 193 },
  { s: 1, x: 140, y: 367 },
  { s: 0.85, x: 335, y: 420 },
  { s: 1, x: 314, y: 40 },
  { s: 0.85, x: 310, y: 230 },
  { s: 0.85, x: 460, y: 87 },
  { s: 0.8, x: 520, y: 387 },

  { s: 0.7, x: 570, y: 200 },
  { s: 0.85, x: 723, y: 365 },
  { s: 0.85, x: 710, y: 193 },
  { s: 1, x: 790, y: 71 },
  { s: 1, x: 870, y: 208 },
  { s: 1, x: 1000, y: 88 },
  { s: 0.85, x: 1010, y: 320 },

  { s: 0.85, x: 1140, y: 60 },
  { s: 1, x: 1160, y: 270 },
  { s: 0.85, x: 1260, y: 382 },
  { s: 1, x: 1300, y: 90 },
  { s: 0.85, x: 1420, y: 232 },
  { s: 0.85, x: 1520, y: 122 },
  { s: 0.85, x: 1550, y: 362 },

  { s: 0.85, x: 1660, y: 150 },
  { s: 1, x: 1700, y: 278 },
  { s: 0.85, x: 1820, y: 85 },
  { s: 1, x: 1890, y: 238 },
  { s: 1, x: 1940, y: 385 },
  { s: 1, x: 2020, y: 144 },
  { s: 0.75, x: 2130, y: 415 },

  { s: 0.85, x: 2140, y: 256 },
  { s: 1, x: 2260, y: 103 },
  { s: 1, x: 2340, y: 271 },

  // { s: 1, x: 2495, y: 271 },
  // { s: 1, x: 1990, y: 75 },
  // { s: 0.65, x: 1344, y: 65 },
  // { s: 1, x: 901, y: 385 },
  // { s: 0.85, x: 1581, y: 422 },
  // { s: 0.85, x: 1721, y: 420 },
  // { s: 0.85, x: 252, y: 400 },
  // { s: 0.85, x: 718, y: 400 },
  // { s: 1, x: 772, y: 644 },
  // { s: 0.65, x: 2755, y: 340 },
  // { s: 0.65, x: 2400, y: 420 },
  // { s: 0.65, x: 2455, y: 100 },
  // { s: 0.65, x: 3000, y: 420 },
  // { s: 0.65, x: 2800, y: 430 },
  // { s: 0.75, x: 3480, y: 70 },
  // { s: 0.70, x: 3200, y: 40  },
  // { s: 0.85, x: 380, y: 100 },
  // { s: 0.85, x: 255, y: 200 },
  // { s: 0.85, x: 391, y: 291 },
  // { s: 0.85,  x: 305, y:91 },
  // { s: 0.85,  x: 305, y:91 },
  // { s: 0.85,  x: 305, y:91 },
];

function createStripe(container, specs) {
	const bubbles = []
	const introDelay = 1500
	const introDuration = 1500
	let firstTick = null
	let lastTick = 0
	let playing = 0

	function createBubble({ index, spec }) {
		const bubble = {}
		const { x, y, s } = spec
		const el = document.createElement('div')

		el.className = `stripe-bubble stripe-bubble-${index + 1}`
		bubble.el = el
		bubble.index = index
		bubble.posX = x
		bubble.posY = y
		bubble.scale = s
		bubble.introProgress = 0
		bubble.introDelay = Math.random() * introDelay
		bubble.noiseSeedX = Math.floor(Math.random() * 64000)
		bubble.noiseSeedY = Math.floor(Math.random() * 64000)

		upadateBubble(bubble)

		bubbles.push(bubble)
		container.appendChild(el)
	}

	function upadateBubble(bubble) {
		bubble.noiseSeedX += NOISE_SPEED
		bubble.noiseSeedY += NOISE_SPEED

		const randomX = window.noise.simplex2(bubble.noiseSeedX, 0)
		const randomY = window.noise.simplex2(bubble.noiseSeedY, 0)

		bubble.posX -= SCROLL_SPEED

		const xWithNoise = bubble.posX + randomX * NOISE_AMOUNT
		const yWithNoise = bubble.posY + randomY * NOISE_AMOUNT

		if (bubble.posX < -200) {
			bubble.posX = CANVAS_WIDTH
		}

		bubble.el.style.transform = `translate(${xWithNoise}px, ${yWithNoise}px) scale(${bubble.scale})`
	}

	function createBubblesList() {
		specs.forEach((spec, index) => createBubble({ index, spec }))
	}

	function updateStripe(scene) {
		firstTick || (firstTick = scene)

		const n = (scene -= firstTick) - lastTick

		lastTick = scene

		bubbles.forEach((bubble) => {
			if (bubble.introProgress < 1 && scene > introDelay) {
				bubble.introProgress = Math.min(1, bubble.introProgress + n / introDuration)
			}

			upadateBubble(bubble)
		})

		playing && requestAnimationFrame(updateStripe)
	}

	function isAnimationAllowed() {
		const scene = container.getBoundingClientRect()

		if ((scene.bottom < 0 || scene.top > window.innerHeight) && playing === 1) {
			playing = 0
		} else if (scene.bottom > 0 && scene.top < window.innerHeight && playing === 0) {
			playing = 1
			requestAnimationFrame((scene) => updateStripe(scene))
		}
	}

	function init() {
		createBubblesList()
		isAnimationAllowed()

		window.addEventListener('scroll', throttle(isAnimationAllowed, 500))
	}

	init()
}

const Stripe = () => {
	const containerRef = useRef(null)
	useEffect(() => {
		window.noise.seed(Math.floor(Math.random() * 64000))
		createStripe(containerRef.current, bubblesSpecs)
	}, [])

	return <div className="stripe" ref={containerRef}></div>
}

export default Stripe
