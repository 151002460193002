import React from 'react'

import Icon from '../Icon'

const Timeline = () => {
  return (
    <ul className="timeline">
      <li className="timeline-event">
        <div className="timeline-main">
          <p className="timeline-date">1876</p>
          <p className="timeline-caption">
            30 ноября Павел Яблочков создал первый трансформатор, а 25 июня Александр Белл продемонстрировал
            свой телефон. Мы попробовали начать писать мобильные приложения, но оказалось, что рано.
          </p>
        </div>
        <div className="timeline-dot">
          <Icon name="close" width="16" height="16" className="fill-current" />
        </div>
        <div className="timeline-side">Отложили идею до лучших времен.</div>
      </li>

      <li className="timeline-event">
        <div className="timeline-main">
          <div className="timeline-date">2008</div>
          <div className="timeline-caption">
            Заказали{' '}
            <a className="link link--primary" href="https://en.wikipedia.org/wiki/Android_Dev_Phone" target="_blank">
              Android Dev Phone 1
            </a>{' '}
            – первое устройство на Android. Написали приложение, позволяющее измерять высоту чего-то по
            времени падения телефона. Погрешность в один метр точных измерений не давала.
          </div>
        </div>
      </li>

      <li className="timeline-event">
        <div className="timeline-main">
          <div className="timeline-date">2009</div>
          <div className="timeline-caption">
            Написали много игр:{' '}
            <a
              className="link link--primary"
              href="https://de.androlib.com/android.application.amoneron-android-snowballblow-pqmw.aspx"
              target="_blank"
            >
              Snowballblow
            </a>
            , в которой нужно было дуть в микрофон, чтобы снежный ком катился воздухом.{' '}
            <a
              className="link link--primary"
              href="https://de.androlib.com/android.application.amoneron-android-lumbricidae-Eip.aspx"
              target="_blank"
            >
              Lumbricidae
            </a>
            ,{' '}
            <a
              className="link link--primary"
              href="https://de.androlib.com/android.application.amoneron-android-innerscope-qjBp.aspx"
              target="_blank"
            >
              Innerscope
            </a>
            , о которой{' '}
            <a className="link link--primary" href="https://habr.com/ru/post/67140/" target="_blank">
              писали на Хабре
            </a>
            ,{' '}
            <a
              className="link link--primary"
              href="https://de.androlib.com/android.application.amoneron-android-slugspro-qzEwF.aspx"
              target="_blank"
            >
              Slugs
            </a>{' '}
            и другие. Суммарная аудитория превысила 600 тыс. человек, маркетинга в Android Market в то время
            не было вообще. Поняли, как сотрудничать с пользователями.
          </div>
        </div>
      </li>

      <li className="timeline-event">
        <div className="timeline-main">
          <div className="timeline-date">2010</div>
          <div className="timeline-caption">
            App Store к этому моменту существовал уже два года, устройств в России стало заметное количество.
            Пора. Начали с превращения{' '}
            <a className="link link--primary" href="https://habr.com/ru/post/105756/" target="_blank">
              iPad в счеты
            </a>
            , закончили год переписыванием части игр на iOS.
          </div>
        </div>
      </li>

      <li className="timeline-event">
        <div className="timeline-main">
          <div className="timeline-date">2011</div>
          <div className="timeline-caption">
            Открыли компанию ООО "Тринаум", цель – найти издателя (
            <a
              className="link link--primary"
              href="https://ru.wikipedia.org/wiki/%D0%98%D0%B7%D0%B4%D0%B0%D1%82%D0%B5%D0%BB%D1%8C_%D0%BA%D0%BE%D0%BC%D0%BF%D1%8C%D1%8E%D1%82%D0%B5%D1%80%D0%BD%D1%8B%D1%85_%D0%B8%D0%B3%D1%80"
              target="_blank"
            >
              кто это такой?
            </a>
            ) для выхода на международные рынки. Адаптировали приложения на iOS для планшетов, наращивали
            Android–аудиторию обновлениями, встречались с издателями.
          </div>
        </div>
        <div className="timeline-dot timeline-dot--empty"></div>
        <div className="timeline-side">
          800 тыс. активных пользователей, <br /> более 2 млн установок приложений.
        </div>
      </li>

      <li className="timeline-event">
        <div className="timeline-main">
          <div className="timeline-date">2012</div>
          <div className="timeline-caption">
            Заключили договор с японской{' '}
            <a
              className="link link--primary"
              href="https://en.wikipedia.org/wiki/Marvelous_(company)"
              target="_blank"
            >
              Marvelous
            </a>
            , специализирующейся на азиатском рынке. Многое узнали про специфику азиатского рынка, разработку
            цифровых продуктов и практику их продвижения на разных рынках. В результате проект <br />{' '}
            <a
              className="link link--primary"
              href="https://www.gameinformer.com/games/eyes_attack/b/ios/archive/2013/07/22/latest-title-from-marvelous-games-coming-to-ios-august.aspx"
              target="_blank"
            >
              Eyes Attack
            </a>{' '}
            был размещен Apple в разделе рекомендуемых в 64 странах.
          </div>
        </div>
      </li>

      <li className="timeline-event">
        <div className="timeline-main">
          <div className="timeline-date">2013</div>
          <div className="timeline-caption">
            Взгляд изнутри на рынок геймдева формирует ясное представление о нем, как о рулетке. В то время
            как утилитарные мобильные приложения нужны всем каждый день. Пытаемся вывести огромную аудиторию
            Eyes Attack в прибыль и параллельно открываем отдел разработки мобильных приложений.
          </div>
        </div>
        <div className="timeline-dot timeline-dot--empty"></div>
        <div className="timeline-side">Переезжаем, пока вмещаемся в одну большую Газель.</div>
      </li>

      <li className="timeline-event">
        <div className="timeline-main">
          <div className="timeline-date">2014</div>
          <div className="timeline-caption">
            Рынок разработки мобильных приложений в России только формируется, никто не понимает их стоимость,
            но они нужны всем. Много клиентов из разных городов, начинаем смещать акцент с игр на приложения.
            Научились работать с разными методами монетизации.
          </div>
        </div>
      </li>

      <li className="timeline-event">
        <div className="timeline-main">
          <div className="timeline-date">2015</div>
          <div className="timeline-caption">
            Регистрация ООО "ЭппКрафт" с фокусировкой исключительно на приложения с частичным циклом: есть
            дизайн, клиентская и серверная разработка. Десять первых коммерческих продуктов. Первые зарубежные
            клиенты.
          </div>
        </div>
      </li>

      <li className="timeline-event">
        <div className="timeline-main">
          <div className="timeline-date">2016</div>
          <div className="timeline-caption">
            Делаем упор на дизайн и UX: появляются и новые размеры экрана, и новые тренды, и более высокие
            требования к анимации. Отлаживаем механизм гарантийной поддержки разработанных продуктов. Более 30
            опубликованных мобильных приложений.
          </div>
        </div>
        <div className="timeline-dot timeline-dot--empty"></div>
        <div className="timeline-side">Переезд в новый прекрасный офис, чтобы всем вместиться.</div>
      </li>

      <li className="timeline-event">
        <div className="timeline-main">
          <div className="timeline-date">2017</div>
          <div className="timeline-caption">
            Появляются крупные проекты: система автоматического скоринга стиля вождения автомобиля для
            международной{' '}
            <a className="link link--primary" href="https://ru.wikipedia.org/wiki/AXA" target="_blank">
              AXA Group
            </a>
            , совместные проекты со Сбербанком, российскими медицинскими сообществами и Общественной палатой
            РФ, Московским Метрополитеном. И... как же без блокчейна, когда все что-то с ним делают.
          </div>
        </div>
      </li>

      <li className="timeline-event">
        <div className="timeline-main">
          <div className="timeline-date">2018</div>
          <div className="timeline-caption">
            Попадаем с внутренним проектом в <br />{' '}
            <a className="link link--primary" href="https://www.ycombinator.com/" target="_blank">
              Y Combinator
            </a>
            , учимся у них, как делать правильные продукты и выводить их на рынок. Расширяем и улучшаем отдел
            тестирования. Более 130 мобильных приложений.
          </div>
        </div>
        <div className="timeline-dot timeline-dot--empty"></div>
        <div className="timeline-side">Еще раз переезжаем, и на этот раз с запасом по пространству.</div>
      </li>

      <li className="timeline-event">
        <div className="timeline-main">
          <div className="timeline-date">2019</div>
          <div className="timeline-caption">
            Улучшаем организационную структуру: команды из пяти, двадцати и пятидесяти специалистов
            взаимодействуют по-разному. Запускаем проекты по страхованию в партнерстве с Ингосстрахом.
            Работаем над собственными продуктами.
          </div>
        </div>
      </li>

      <li className="timeline-event">
        <div className="timeline-main">
          <div className="timeline-date">2020</div>
          <div className="timeline-caption">
          На рынке масса IT решений разной степени сложности. Мы стремимся к тому, чтобы стать тем местом, где всегда помогут выбрать наиболее оптимальное решение проблемы, даже если оно не будет связано с мобильным приложением.
          </div>
        </div>
      </li>
      <li className="timeline-event">
        <div className="timeline-main">
          <div className="timeline-date">2021</div>
          <div className="timeline-caption">
          2020 год внес свои коррективы, но мы продолжаем двигаться к целям. Планируем замкнуть цикл услуг разработки мобильных приложений. От первоначальной аналитики до маркетинга и продвижения. Если все этапы не взаимосвязаны - запустить хороший сервис невозможно.
          </div>
        </div>
      </li>
      <li className="timeline-event">
        <div className="timeline-main">
        <div className="timeline-date">2022</div>
          <div className="timeline-caption">
          Закрываем полный цикл услуг по разработке мобильных приложений и сервисов. Время для быстрых реакций к изменениям в мире. Расширяемся в направлении консалтинга и аудита, для закрытия максимального спектра потребностей наших клиентов.
          </div>
        </div>
      </li>
      <li className="timeline-event">
        <div className="timeline-main">
          <div className="timeline-date">2023</div>
          <div className="timeline-caption">
            Масштабно внедрили разработку на Flutter — растим потенциал в работе с кросс-платформенными мобильными и веб приложениями. Перешли на новую ступень в создании корпоративных мобильных сервисов: отточили вопрос интеграции с 1С и другими системами на стороне заказчика. Этот опыт помогает объединять разветвленную работу подразделений компаний в простое пространство, которое всегда под рукой.
          </div>
        </div>
      </li>
      <li className="timeline-event timeline-event_current-rigth">
        <div className="timeline-main">
          <div className="timeline-date">2024</div>
          <div className="timeline-caption">
            СТАРТАПЫ. Проекты, к которым были причастны, достигают успехов на рынке.
            Например, становятся частью перспективных цифровых экосистем, как у МТС.
            <br></br>
            <br></br>
            КОРПОРАТИВНЫЙ СЕКТОР. Новые реалии экономики, больше вызовов для бизнесов.
            Помогаем с ними справляться: оптимизировать процессы, снижать издержки, становиться ближе к клиентам.
          </div>
        </div>
        <div className="timeline-dot">
          <Icon name="star" width="20" height="20" className="fill-current" />
        </div>
        <div className="timeline-side">Вы находитесь здесь.</div>
      </li>
      <li className="timeline-event timeline-event_inactive">
        <div className="timeline-main timeline-main_end">
          <div className="timeline-date">2025</div>
          <div className="timeline-caption">
            Переход на новый уровень по отраслям и потенциалу в решении бизнес-задач.
          </div>
        </div>
      </li>
    </ul>
  )
}

export default Timeline
