import React, { useState, useEffect } from 'react'
import { animated, useSpring } from 'react-spring'

const Boop = ({
  children,
  styles,
  path,
  x = 0,
  y = 0,
  rotation = 0,
  scale = 1.1,
  timing = 150,
  picture = null,
  text = '',
  anchor,
}) => {
  const [isBooped, setIsBooped] = useState(false)
  const style = useSpring({
    backfaceVisibility: 'hidden',
    transform: isBooped
      ? `translate(${x}px, ${y}px)
             rotate(${rotation}deg)
             scale(${scale})`
      : `translate(0px, 0px)
             rotate(0deg)
             scale(1)`,
    config: {
      tension: 300,
      friction: 10,
    },
  })

  useEffect(() => {
    setTimeout(() => {
      setIsBooped(false)
    }, timing)
  }, [isBooped])

  const trigger = () => setIsBooped(true)

  const onClick = () => {
    setIsBooped(true)
    !!anchor && anchor.current.scrollIntoView({ behavior: 'smooth' })
  }

  return (
    <>
      {!!path && !picture && !!children && (
        <animated.a className={styles} onMouseEnter={trigger} style={style} href={path} target="_blank">
          {children}
        </animated.a>
      )}
      {!!picture && !path && (
        <button className={styles} onMouseEnter={trigger} onClick={onClick}>
          {text}
          <animated.img src={picture} style={style} alt="" />
        </button>
      )}
    </>
  )
}

export default Boop
