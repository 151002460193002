import React, { useRef, useState } from 'react'
import { animated, useSpring } from 'react-spring'

import bgImage from '../../images/whitepaper-bg.svg'

const Whitepaper = () => {
  const [isOpen, setIsOpen] = useState(false)
  const contentRef = useRef(null)
  const contentStyles = useSpring({
    config: { duration: 300 },
    height: isOpen ? contentRef.current.scrollHeight : 0,
  })

  const toggleContent = () => setIsOpen(prev => !prev)

  return (
    <div className="whitepaper container mb-24">
      <div className="whitepaper-trigger" onClick={toggleContent}>
        <p className="whitepaper-title">White paper</p>
        <p className="whitepaper-subtitle">Принципы работы нашей компании</p>
        <div className="whitepaper-btn btn btn--primary h-9 px-4 text-xs uppercase">
          {isOpen ? 'Свернуть' : 'Читать'}
        </div>
        <img className="whitepaper-media" src={bgImage} alt="" />
      </div>

      <animated.div
        className="whitepaper-content"
        ref={contentRef}
        style={contentStyles}
      >
        <div className="px-6 sm:px-8 py-4 sm:py-6 md:p-12">
          <p>
            Наша цель – не выполнить техническое задание, а сделать продукт.
          </p>

          <p>
            Это непросто: дизайн и программирование – только небольшая часть
            необходимой работы. Сначала нужно проанализировать рынок и понять,
            что именно нужно и не нужно делать. После этого разрабатывается
            маркетинговая стратегия с пониманием плана по захвату аудитории и
            мира. Техническое задание на разработку должно в себе отражать как
            выводы из аналитики, так и маркетинговые мысли.
          </p>

          <p>
            Дизайн, UX, тестирование, мягкий запуск, цикличные обновления – все
            должно работать слаженно и отвечать единой цели удовлетворения
            потребности пользователя. Отсутствие этой слаженности – основная
            проблема российского рынка разработки мобильных приложений. Бизнесу
            не нужен код, сам факт публикации в магазинах или трафик. Ему нужен
            продукт – инструмент решения проблем людей и зарабатывания денег.
          </p>

          <p>
            Мы хотим изменить рынок, предлагая услуги по созданию продукта, а не
            его частей. Их сложно собрать в единый инструмент, особенно когда
            они делались разными людьми, сфокусированными на одной конкретной
            задаче. В то время как каждое действие в разработке - от цвета
            кнопки до возможности ответить на уведомление – это маркетинг.
          </p>

          <p>
            Часть этого плана – создание лаборатории-инкубатора, в которой
            совместно будут работать специалисты и по технике, и по рынку, и по
            бизнесу. Мы выстраиваем цикл полной разработки и привлекаем
            инвесторов, заинтересованных в продуктах наших клиентов, чтобы
            совместными усилиями делать большие истории.
          </p>

          <p>
            Единственный способ делать работу хорошо – любить то, что ты
            делаешь. Поэтому при выборе между кандидатами на собеседованиях, при
            принятии непростых решений по повышениям в должностях, изменению
            условий работы и увольнениям специалистов мы в первую очередь
            ориентируемся на страсть человека к своему делу. Безусловно, важны
            многие факторы, но если нет внутреннего интереса, ничего хорошего из
            этого не выйдет.
          </p>
        </div>
      </animated.div>
    </div>
  )
}

export default Whitepaper
