import React from 'react'
import Img from 'gatsby-image'
import { graphql, useStaticQuery } from 'gatsby'
import SwiperCore, { Autoplay } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { AnchorLink } from 'gatsby-plugin-anchor-links'

SwiperCore.use([Autoplay])

const AboutUs = () => {
  const {
    allFile: { edges: slides },
  } = useStaticQuery(graphql`
    query {
      allFile(filter: { relativePath: { regex: "/office/" } }) {
        edges {
          node {
            relativePath
            childImageSharp {
              fluid(maxWidth: 680, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const renderSlides = slides.map(({ node: slide }, key) => (
    <SwiperSlide key={key}>
      <Img
        fluid={slide.childImageSharp.fluid}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          objectFit: 'cover',
          borderRadius: '16px',
          overflow: 'hidden',
        }}
      />
    </SwiperSlide>
  ))

  return (
    <section className="aboutus container">
      <div className="aboutus-body">
        <div className="aboutus-caption">
          <p>
            Мы работаем командой с 2011 года без привлечения фрилансеров и
            аутсорса, только налаженные рабочие процессы и плотное общение друг
            с другом.
          </p>
          <p>
            На нашем счету более 200 разработанных мобильных приложений:
            мессенджеры, социальные сети, банковские системы, e-commerce и
            сложные корпоративные решения.
          </p>
        </div>

        <AnchorLink
          to="/about#ourpath"
          className="btn btn--primary w-full md:w-auto md:px-20 py-4 sm:text-22 sm:py-5 leading-none"
        >
          Подробнее
        </AnchorLink>
      </div>

      <div className="aboutus-slider">
        <Swiper
          spaceBetween={16}
          slidesPerView={1}
          loop={true}
          effect="fade"
          autoplay={{ delay: 5000 }}
          simulateTouch={false}
        >
          {renderSlides}
        </Swiper>
      </div>
    </section>
  )
}

export default AboutUs
